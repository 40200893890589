var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "countries" }, [
            _vm.countries !== null
              ? _c(
                  "span",
                  [
                    _c("b", [_vm._v("Countries:")]),
                    _vm._l(_vm.countries.userCountries, function(country, i) {
                      return _c("span", { key: i, staticClass: "country" }, [
                        _vm._v(" " + _vm._s(country.Country_Name))
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }