<template>
  <div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div class="countries" v-else>
      <span v-if="countries !== null">
        <b>Countries:</b>
        <span
          class="country"
          v-for="(country, i) in countries.userCountries"
          :key="i"
        >
          {{ country.Country_Name }}</span
        ></span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    isLoader: true,
  }),
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
  },
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
  },
  computed: {
    ...mapGetters({
      countries: "GET_USER_ROLE",
    }),
  },
};
</script>
<style lang="scss">
.countries {
  text-align: center;
  margin: -5px 10px 5px 10px;
  width: 210px;
  .country::after {
    content: ",";
  }
  .country:last-child::after {
    content: " ";
  }
}
</style>
